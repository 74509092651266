@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

@mixin active-color($color) {
  border-color: $color;
  outline-color: $color;
  box-shadow: rem(0 0 0 2px) color.change($color, $alpha: 0.4);
}

.ui-kit-form-control {
  .ui-kit-select {
    font-weight: $font-weight-light;
    font-size: $font-size-small;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    color: map.get($colors, 'textDark', 'dark');
    &-error {
      $color: map.get($colors, 'error', 'main');
      border: 2px solid $color;
      .ant-select-selector {
        border: none;
      }
    }
  }
  .ant-select-selector {
    .ant-select-selection-item {
      color: map.get($colors, 'textDark', 'dark');
    }
  }
}