@import 'styles/all.scss';

.ProductBaseForm {
  display: flex;
  flex-direction: column;
  gap: rem(40px);

  @include media('<md') {
    gap: rem(24px);
  }

  &-main-section {
    display: flex;
    gap: rem(40px);

    @include media('<lg') {
      flex-direction: column;
    }

    @include media('<md') {
      gap: rem(24px);
    }

    &__details {
      flex: 0 1 410px;
      margin-right: auto;
      gap: rem(40px);
      display: flex;
      flex-direction: column;

      @include media('<=md') {
        flex: 1;
        margin: 0px;
      }

      &-price {
        margin-top: 1rem;
        display: flex;
        align-items: flex-start;
        gap: rem(18px);

        &-content {
          flex: 0 1 410px;

          &-value {
            line-height: rem(40px);
            font-weight: $font-weight-bold;
            margin: 0px;

          }
        }
      }
      @include media('<md') {
        flex: 0;
        gap: rem(24px);
      }

      &-price-type {
        .ant-select-selector {
          display: inline-flex;
          align-items: center;
          height: rem(50px);
          padding: rem(6px 14px);
          
          .ant-select-selection-item {
            line-height: 1.4375em;
          }
        }
      }

      &-price-variations {
        margin-top: 1rem;

        .price-variation {
          display: flex; 
          gap: 8px; 
          margin-bottom: 10px;


          &-price-content {

            &-value {
              font-weight: $font-weight-bold;
              margin: 0px;

            }
          }
          

          .timeLabel {
            .ant-select-selector {
              display: inline-flex;
              align-items: center;
              height: rem(50px);
              padding: rem(6px 14px);
              
              .ant-select-selection-item {
                line-height: 1.4375em;
              }
            }
            
          }

          .ant-form-item {
            flex: 1;
          }

          .ui-kit-form-control:not(:first-child) {
            margin-top: 0px;
          }
        }
      }
    }
    &__uploadImage {
      max-width: rem(262px);
    }
  }
}
